<template>
    <div class="write-ware">
        <div class="top-info">
            <span class="import-pre" @click="$emit('importPrePaper', writePaper.paperId, 1)">导入上一篇日志</span>
            <span class="pull-right" v-if="writePaper.createDate">上次保存时间：{{writePaper.createDate}}</span>
        </div>
        <div class="record-cont">
            <template v-for="(item, index) in fieldsContent">
                <!-- 1：文本 2：数字 3：下拉 4：日期 5：外部联系人 100：备注 101：图片 102：附件 -->
                <!-- 年 yyyy，月 MM，日 dd，小时 HH，分 mm，秒 ss -->
                <!-- value 1：日期和时间  (datetime-local) 2：日期 (date) 3：时间 (time) -->
                <template v-if="item.fieldType != 101 && item.fieldType != 102">
                    <div :key="index" v-if="item.fieldType === 1 || item.fieldType === 100" class="field-ware" @click.self="focusThis(curIndex = index)">
                        <span class="static" :class="{focus:curIndex === index, 'focus-content': paperContent[item.fieldId].content !== ''}">{{item.cnName}}</span>
                        <van-field v-show="curIndex === index || paperContent[item.fieldId].content !== ''" class="cus-field-ware" ref="VanField" v-model="paperContent[item.fieldId].content" type="textarea" placeholder="请输入内容" rows="1" autosize />
                    </div>
                    <div :key="index" v-else-if="item.fieldType === 2" class="field-ware" @click.self="focusThis(curIndex = index)">
                        <span class="static" :class="{focus:curIndex === index, 'focus-content': paperContent[item.fieldId].content !== ''}">{{item.cnName}}</span>
                        <van-field v-show="curIndex === index || paperContent[item.fieldId].content !== ''" class="cus-field-ware" ref="VanField" v-model="paperContent[item.fieldId].content" type="textarea" placeholder="请输入数字" rows="1" autosize />
                    </div>
                    <div :key="index" v-else-if="item.fieldType === 3" class="field-ware" @click.self="focusThis(curIndex = index, 'select')">
                        <span class="static" :class="{focus:curIndex === index, 'focus-content': paperContent[item.fieldId].content !== ''}">{{item.cnName}}</span>
                        <div v-show="curIndex === index || paperContent[item.fieldId].content !== ''" class="cus-field-ware select-field" ref="VanField">
                            <span>{{paperContent[item.fieldId].content}}</span>
                        </div>
                        <van-action-sheet v-model="showSelectOption" :actions="getSelectOption(item.value)" cancel-text="取消" @select="selectVal($event, item.fieldId)" @cancel="onCancel(item.fieldId)" />
                    </div>
                    <div :key="index" v-else-if="item.fieldType === 4" class="field-ware" @click.self="focusThis(curIndex = index)">
                        <span class="static" :class="{focus:curIndex === index, 'focus-content': paperContent[item.fieldId].content !== ''}">{{item.cnName}}</span>
                        <span style="display: none">{{transTime(item.value, item.fieldId, paperContent[item.fieldId].content)}}</span>
                        <input v-if="item.value === '3'" type="time" v-show="curIndex === index || paperContent[item.fieldId].content !== ''" class="cus-field-ware" ref="VanField" v-model="paperContent[item.fieldId].content" />
                        <input v-else-if="item.value === '2'" type="date" v-show="curIndex === index || paperContent[item.fieldId].content !== ''" class="cus-field-ware" ref="VanField" v-model="paperContent[item.fieldId].content" />
                        <input v-else-if="item.value === '1'" type="datetime-local" v-show="curIndex === index || paperContent[item.fieldId].content !== ''" class="cus-field-ware" ref="VanField" v-model="paperContent[item.fieldId].content" />
                    </div>
                </template>
            </template>
        </div>
        <div class="img-annex">
            <div class="field-ware">
                <span class="static">图片</span>
                <span class="pull-right">
                    <i class="icon iconfont icon-pic" @click="proxyEvent(1)"></i>
                </span>
                <div class="show-img-annex">
                    <span @click="previewImage(item10)" class="iconBox" v-for="(item10, index10) in imgsList" :key="index10">
                        <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                        <svg v-else class="iconSVG" aria-hidden="true">
                            <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                        </svg>
                        <i @click.stop="delThis(item10.ossKey, index10, 1)" class="iconfont icon-remove"></i>
                    </span>
                </div>
            </div>
            <div class="field-ware">
                <span class="static">附件</span>
                <span class="pull-right">
                    <i class="icon iconfont icon-attachment" @click="proxyEvent(2)"></i>
                </span>
                <div class="show-img-annex">
                    <div @click="previewImage(item10)" class="iconBox-ware" v-for="(item10, index10) in filesList" :key="index10">
                        <div class="icon-img">
                            <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                            <svg v-else class="iconSVG" aria-hidden="true">
                                <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                            </svg>
                        </div>
                        <div class="contentBox">
                            <div class="content">
                                <p class="fileName ellipsis" v-html="item10.name"></p>
                                <p class="fileInfo ellipsis" :size="Global.utils.byteCalc(item10.size)">
                                    {{getSize(item10.size)}}
                                </p>
                            </div>
                        </div>
                        <i @click.stop="delThis(item10.ossKey, index10, 2)" class="iconfont icon-remove pull-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="submit-paper">
            <van-button class="submit-btn" v-if="!isLoading4Save" @click="submitForm(1)" type="danger">提交</van-button>
            <van-button class="submit-btn" v-else loading type="danger" loading-text="提交中..." />
        </div>
        <upload-files ref="uploadFile" needAccessToken="true" :filesNum="uploadContent == '1' ? filesNum : {}"></upload-files>
    </div>
</template>

<script>
import UploadFiles from '@/components/UploadFiles/index'
export default {
    name: 'WritePaper',
    props: {
        paperContent: {
            type: Object
        },
        contentRules: {
            type: Object
        },
        fieldsContent: {
            type: Array
        },
        writePaper: {
            type: Object
        }
    },
    components: {
        'upload-files': UploadFiles
    },
    data() {
        return {
            imgsList: [],
            filesList: [],
            message: '',
            curIndex: '',
            isLoading4Save: false, // 保存-加载状态
            showSelectOption: false,
            uploadContent: '',
            filesNum: {
                hasNum: 0, // 已有文件数
                maxNum: 9 // 最多文件数
            }
        }
    },
    watch: {
        'showSelectOption': {
            handler(newV, oldV) {
                if (!newV) this.curIndex = ''
            },
            deep: true
        },
        paperContent: {
            handler(newV, oldV) {
                this.initAnnex()
            },
            deep: true
        }
    },
    created() {
        this.setHeaderTitle(this.writePaper.name)
        this.initAnnex()
    },
    mounted() { },
    methods: {
        initAnnex() {
            let dataArr = Object.values(this.paperContent)
            let imgArr = dataArr.filter(item => item.fieldType == 101)
            let fileArr = dataArr.filter(item => item.fieldType == 102)
            if (imgArr.length > 0) {
                this.imgsList = JSON.parse(imgArr[0].content || '[]')
            }
            if (fileArr.length > 0) {
                this.filesList = JSON.parse(fileArr[0].content || '[]')
            }
        },
        computedShow(curIndex, index, content) {
            if (curIndex === index || content !== '') {
                this.showSelectOption = true
            }
        },
        getSelectOption(val) {
            let pickOption = []
            pickOption = val.split(',').map(v => {
                return {
                    name: v
                }
            })
            return pickOption
        },
        selectVal(item, fieldId) {
            this.paperContent[fieldId].content = item.name
            this.showSelectOption = false
        },
        onCancel(fieldId) {
            this.paperContent[fieldId].content = ''
            this.showSelectOption = false
        },
        substringSuffix(name) {
            let str = name.substr(name.lastIndexOf('.') + 1)
            return str
        },
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            if (exe) {
                return imgArr.includes(exe.toLowerCase())
            }
        },
        async previewImage(item) {
            try {
                let suffix = this.substringSuffix(item.name)
                if (!this.Global.utils.filePreview.isSupport(suffix)) {
                    this.$toast('暂不支持该格式文件预览')
                    return
                }
                this.Global.utils.filePreview.open(item.url, suffix, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${suffix}`)
            } catch (error) {
                alert(error)
            }
        },
        // changeFile(ref, m) {
        //     this.uploadContent = ref
        //     let fileSizeM = (this.$refs[ref].files[0].size / 1048576).toFixed(2) // 转成M，并保存2位小数
        //     if (fileSizeM > m) {
        //         this.$toast.fail('包含超过 ' + m + ' M的文件不能上传')
        //         return
        //     }
        //     // this.$refs.fileuploadDialog.upload(this.$refs[ref])
        //     this.$refs[ref].value = '' // 清空上次上传内容
        // },
        proxyEvent(n) {
            // 1 图片, 2 附件
            if (n === 1) {
                if (this.imgsList.length >= 9) {
                    this.$toast.fail('每次最多上传9张图片哦')
                    return
                }
            }
            this.uploadContent = n
            this.callUpload(n)
        },
        callUpload(n) {
            let fileUploadUtils = this.$refs.uploadFile.init({
                formName: 'uploadFile',
                accept: n === 1 ? 'image/*' : '.',
                method: 'post',
                uploadUrl: this.Global.config.apiBaseURL + this.Global.api.v1.paper_upload_resource
            })
            // 单个文件上传成功
            fileUploadUtils.on('load', (res) => {
                if (n === 1) {
                    this.imgsList.push({
                        name: res.data.fileName,
                        url: res.data.url,
                        ossKey: res.data.ossKey,
                        size: (res.data.fileSize / 1024).toFixed(2),
                        status: 1
                    })
                } else if (n === 2) {
                    this.filesList.push({
                        name: res.data.fileName,
                        url: res.data.url,
                        ossKey: res.data.ossKey,
                        size: (res.data.fileSize / 1024).toFixed(2),
                        status: 1
                    })
                }
            })
            // 全部传输完成，成功失败都触发
            fileUploadUtils.on('loadend', (res) => {
                // console.log('123loadend', res)
            })
            this.filesNum.hasNum = this.imgsList.length
            fileUploadUtils.trigger()
        },
        async delThis(ossKey, index, t) {
            try {
                // t: 1 图片, 2 附件
                let param = {
                    ossKey: [ossKey]
                }
                if (ossKey === 'save' || ossKey === 'del') {
                    // 保存, 先删除图片附件
                    let imgAndFile = [...this.imgsList, ...this.filesList]
                    param.ossKey = imgAndFile.map(v => {
                        return v.ossKey
                    })
                    // 还没有上传图片或者附件
                    if (param.ossKey.length === 0) {
                        if (ossKey === 'save') this.submitForm(2) // 调用保存
                        return
                    }
                }
                let res = await this.axios.delete(this.Global.config.apiBaseURL + this.Global.api.v1.paper_upload_resource, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (ossKey === 'save') {
                        this.imgsList = []
                        this.filesList = []
                        this.submitForm(2) // 调用保存
                    } else if (ossKey === 'del') {
                        this.imgsList = []
                        this.filesList = []
                    }
                    if (t === 1) this.imgsList.splice(index, 1)
                    if (t === 2) this.filesList.splice(index, 1)
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        focusThis(index, name) {
            this.$nextTick(() => {
                if (name === 'select') {
                    this.showSelectOption = true
                } else {
                    this.$refs.VanField[index].focus()
                }
            })
        },
        transTime(value, fieldId, content) {
            // item.value, item.fieldId, paperContent[item.fieldId].content
            // console.log(value, fieldId, content)
            // 时分 + 秒
            if (value === '3' && content && content.split(':').length === 2) this.paperContent[fieldId].content = content + ':00'
        },
        getProp(fieldId, notNull) {
            if (notNull) {
                return fieldId + '.content'
            } else {
                return ''
            }
        },
        getSize(size) {
            if (size > 1024) {
                return parseFloat(size / 1024).toFixed(2) + ' M'
            } else {
                return parseFloat(size).toFixed(2) + ' KB'
            }
        },
        // 提交or保存
        async submitForm(id) {
            this.isLoading4Save = true
            let paramObj = {}
            if (this.writePaper.detailId) paramObj['detailId'] = this.writePaper.detailId
            paramObj['paperId'] = this.writePaper.paperId
            paramObj['status'] = id // 提交类型 1:提交 2:保存
            paramObj['content'] = Object.values(this.paperContent)
            paramObj.content = paramObj.content.map(v => {
                let _v = { ...v }
                if (_v.fieldType === 101) {
                    if (this.imgsList.length > 0) {
                        let _imgsList = JSON.parse(JSON.stringify(this.imgsList))
                        _imgsList = _imgsList.map(item => {
                            let _item = { ...item }
                            delete _item.url
                            return { ..._item }
                        })
                        _v.content = _imgsList
                    } else {
                        _v.content = ''
                    }
                }
                if (_v.fieldType === 102) {
                    if (this.filesList.length > 0) {
                        let _filesList = JSON.parse(JSON.stringify(this.filesList))
                        _filesList = _filesList.map(item => {
                            let _item = { ...item }
                            delete _item.url
                            return { ..._item }
                        })
                        _v.content = _filesList
                    } else {
                        _v.content = ''
                    }
                }
                if (_v.fieldType == 4 && _v.value == 3) {
                    let time = new Date(_v.content)
                    _v.content = time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds()
                }
                if (_v.fieldType == 4) {
                    if (_v.content.indexOf('T') != -1) {
                        _v.content = _v.content.replace(/T/, ' ')
                    }
                }
                return { fieldId: _v.fieldId, content: _v.content }
            })
            this.executeSubmit(paramObj)
        },
        async executeSubmit(paramObj) {
            try {
                let res = await this.axios.post(this.Global.config.apiBaseURL + this.Global.api.v1.handle_paper, paramObj)
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    // 清空输入信息
                    for (const item in this.paperContent) {
                        this.paperContent[item].content = ''
                    }
                    this.imgsList = []
                    this.filesList = []
                    this.curIndex = ''
                    this.isLoading4Save = false
                    this.$emit('update:writeLeave', false) // 关闭写志页面
                } else {
                    this.$toast.fail(res.msg)
                    setTimeout(() => {
                        this.$toast.clear()
                        this.isLoading4Save = false
                    }, 3000)
                }
            } catch (err) {
                console.log(err)
            }
        }
    },
    beforeDestroy() {
        console.log('销毁写日志组件')
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
