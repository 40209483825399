<template>
    <div class="paper-ware">
        <transition name="van-fade">
            <div class="paper-list" v-if="!isWritePaper && !isLoading">
                <div class="recent" v-if="usedList.length > 0">
                    <div class="desc">最近使用</div>
                    <template v-for="(item, index) in usedList">
                        <div :key="index" class="paper-temp" @click="getPaperInputItem(item.paperId, item.name)">
                            <div class="content">
                                <span class="paper-type">{{item.paperType === 1 ? '日' : item.paperType === 2 ? '周' : '月'}}</span>
                                <span class="paper-name ellipsis">{{item.name}}</span>
                                <span class="pre-sub-time pull-right">{{item.lastTime}}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="other" v-if="otherList.length > 0">
                    <div class="desc">其它</div>
                    <template v-for="(item, index) in otherList">
                        <div :key="index" class="paper-temp" @click="getPaperInputItem(item.paperId, item.name)">
                            <div class="content">
                                <span class="paper-type">{{item.paperType === 1 ? '日' : item.paperType === 2 ? '周' : '月'}}</span>
                                <span class="paper-name ellipsis">{{item.name}}</span>
                                <span class="pre-sub-time pull-right">{{item.lastTime}}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <nodata v-if="usedList.length === 0 && otherList.length === 0"></nodata>
            </div>
        </transition>
        <transition name="van-fade">
            <write-paper ref="Writing" v-if="isWritePaper && !isLoading" @importPrePaper="getPrePaper" v-bind:writeLeave.sync="isWritePaper" :writePaper="writePaper" :paperContent="paperContent" :contentRules="contentRules" :fieldsContent="fieldsContent"></write-paper>
        </transition>
        <transition name="van-fade">
            <loading v-if="isLoading" size="30px"></loading>
        </transition>
    </div>
</template>

<script>
import titleMixin from '@/mixin/title'
import writePaper from './WritePaper/index'
export default {
    name: 'WriteLog',
    mixins: [titleMixin],
    title: '写日志',
    props: {},
    components: {
        'write-paper': writePaper
    },
    data() {
        return {
            isWritePaper: false,
            isLoading: false,
            usedList: [],
            otherList: [],
            fieldsContent: [],
            paperContent: {}, // 要提交的日志数据
            contentRules: {},
            writePaper: {
                name: '', // 当前选中日志名称
                paperId: '', // 当前选中日志模板的id
                detailId: '', // 有保存但未提交的日志的id
                createDate: '' // 未提交的日志的保存时间
            }
        }
    },
    watch: {},
    created() {
        this.initData()
    },
    mounted() {
    },
    methods: {
        initData() {
            this.Global.utils.rightMenu.setMenu('') // 清空右上角菜单
            this.setHeaderTitle('写日志')
            this.getPaperList()
        },
        async getPaperList() {
            try {
                this.isLoading = true
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_use
                let res = await this.axios.get(url)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.data.usedList.length > 0) {
                        this.usedList = res.data.data.usedList
                    }
                    if (res.data.data.otherList.length > 0) {
                        this.otherList = res.data.data.otherList
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        // 获取日志输入字段
        async getPaperInputItem(id, name) {
            try {
                this.isLoading = true
                this.writePaper.paperId = id
                this.writePaper.name = name
                let param = {
                    paperId: id
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_input_item
                let res = await this.axios.get(url, { params: param })
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.data.length > 0) {
                        this.fieldsContent = [...res.data.data]
                        let key = [...res.data.data].map(item => {
                            return [item.fieldId, item.fieldType, item.value]
                        })
                        let _obj = {}
                        for (let i = 0; i < key.length; i++) {
                            _obj[key[i][0]] = {
                                fieldId: key[i][0],
                                content: '',
                                fieldType: key[i][1],
                                value: key[i][2]
                            }
                        }
                        let ruleTrue = { required: true, message: '请输入内容', trigger: 'blur' }
                        let ruleNum = { type: 'number', message: '请输入数字', trigger: 'blur' }
                        let validRule = {}
                        let validArr = []
                        validArr = this.fieldsContent.map(v => {
                            return [v.fieldId, v.notNull, v.fieldType]
                        })
                        for (let n = 0; n < validArr.length; n++) {
                            if (validArr[n][2] == 2) {
                                // 数字
                                if (validArr[n][1]) {
                                    // 必填
                                    validRule[validArr[n][0] + '.content'] = { ...ruleNum, required: true }
                                } else {
                                    // 非必填
                                    validRule[validArr[n][0] + '.content'] = { ...ruleNum, required: false }
                                }
                            } else {
                                // 其它
                                if (validArr[n][1]) validRule[validArr[n][0] + '.content'] = ruleTrue
                            }
                        }
                        this.contentRules = validRule
                        this.paperContent = _obj
                        this.getPrePaper(id, 2) // 获取上次保存的日志
                    } else {
                        this.paperContent = {}
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        // 获取之前的日志(保存的or提交的)
        async getPrePaper(id, type) {
            try {
                let param = {
                    paperId: id,
                    type: type // 提交:1 保存不提交:2
                }
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v1.handle_paper, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        // 处理添加日志模板字段，导入or上次保存->匹配不到数据的问题
                        let compareArr = [...this.fieldsContent].filter(x => [...res.data].every(y => y.fieldId !== x.fieldId))
                            .map(v => {
                                let _v = { ...v }
                                _v.content = ''
                                return _v
                            })
                        // 清空上次选择的日志模板信息
                        this.writePaper.detailId = ''
                        this.writePaper.createDate = ''
                        if (type === 2) {
                            this.writePaper.detailId = res.data[0].detailId
                            this.writePaper.createDate = res.data[0].createDate
                        }
                        let key = [...res.data, ...compareArr].map(item => {
                            return [item.fieldId, item.fieldType, item.content]
                        })
                        let _obj = {}
                        for (let i = 0; i < key.length; i++) {
                            let newTime = key[i][2]
                            if (key[i][1] === 4) {
                                if (newTime.indexOf(' ') != -1) {
                                    newTime = newTime.replace(/\s/, 'T')
                                }
                            }
                            _obj[key[i][0]] = {
                                fieldId: key[i][0],
                                content: newTime,
                                fieldType: key[i][1]
                            }
                        }
                        this.paperContent = _obj
                    } else {
                        if (type === 1) {
                            // 不存在上一篇日志
                            this.$toast.fail({ message: '您还没有提交过日志哦' })
                        }
                    }
                    this.isWritePaper = true
                } else {
                    this.$toast.fail(res.msg)
                }
                this.isLoading = false
            } catch (err) {
                console.log(err)
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isWritePaper) {
            // 静默保存日志草稿（删除图片和附件）
            // this.$refs.Writing.delThis('save')

            /* // 拦截: 先删除已上传的图片和附件
            let isConfirm = confirm('离开之前是否保存当前未提交的日志？')
            if (isConfirm) {
                // 保存日志
                this.$refs.Writing.delThis('save')
            } else {
                // 只删除不保存
                this.$refs.Writing.delThis('del')
            } */

            // 6-13: 离开写日志页面静默保存(不删除图片和附件)
            this.$refs.Writing.submitForm(2)

            this.isWritePaper = false
            this.$nextTick(() => {
                this.initData()
                next(false)
            })
        } else {
            next()
        }
    },
    beforeDestroy() {
        console.log('beforeDestroy')
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
